.button {
  background: #a19387;
  color: #7a614e;
  box-shadow: 4px 4px 0px 0px #b3aca7 ;
  border: 1px solid #bc7d3c;
  padding: 15px;
  font-size: 0.6em;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;
  left: 0;
  top: 0;
  margin-top: 10px;
}
/* .get-in-touch-button */
@media only screen and (max-width: 760px) {
  .send-form-button {
    display: flex;
    justify-content: center;
  }
}
.button:hover {
  left: 3px;
  top: 3px;
  box-shadow: 0 0 0 0 #95acca;
}
