.hello-area,
.about-area,
.skills-area,
.contact-area {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.text-part {
  width: 65%;
  height: 80%;
}

.about-me-header {
  font-family: 'Cormorant Garamond', serif;
  color: #bf8d7a;
  font-size: 4vh;
  font-weight: bold;
  margin-bottom: 25px;
}

.skills-header {
  font-family: 'Cormorant Garamond', serif;
  color: #bf8d7a;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 80px;
  font-size: 4vh;
  font-weight: bold;
}
.get-in-touch-header {
  font-family: 'Cormorant Garamond', serif;
  color: #bf8d7a;
  margin-bottom: 25px;
  font-size: 5vh;
}
.get-in-touch-text {
  font-family: 'Cormorant Garamond', serif;
  margin-bottom: 25px;
  font-size: 3vh;
}
section {
  margin: 0px auto;
  padding: 100px, 0px;
  max-width: 1000px;
  min-height: 100vh;
}
section::before{
  box-sizing: inherit;
}
section::after{
  box-sizing: inherit;
}
@media screen and (max-width: 760px) {
  .text-part {
    width: 60%;
    height: 45%;
  }
}

.email-wrapper {
  display: flex;
  align-items: center;
}

.email-icon {
  display: flex;
  font-size: 28px;
  margin-right: 10px;
  color: #bf8d7a;

}

.email {
  font-size: 17px;

}