.social-container {
    display: flex;
    position: fixed;
    right: 10px;
    bottom: 0;  
    flex-direction: column;
    list-style: none;
    align-items: flex-end;
    padding-right: 10px;
}  
.social-container::after {
    content: '';
    display: block;
    flex-direction: column;
    margin-right: 10px;
    width: 1px;
    height: 90px;
    background-color: #bf8d7a;
}
.social-icon {
    margin-top: 15px;
    cursor: pointer;
    height: 23px;
    width: 23px;
}
.footer {
    position: fixed;
    left: 0;
    bottom: 10px;
    width: 100%;
    color: #b3aca7;
    text-align: center;
    line-height: 1;
}
.footer  a {
    padding: 10px;
    text-decoration: none;
    color: #b3aca7;
    font-family: 'Roboto Mono', monospace;
    font-size: 12px;
}
@media only screen and (max-width: 760px) {
    .social-container {
        right: 0;
        bottom: 40px;
    }
    .social-container:after {
       display: none;
    }
}
@media screen and (max-width: 760px) {
    .social-icon {
        position: relative;
        transform: translateY(60%);
        margin-top: 10px;
        bottom: 20px;
        height: 30px;
        width: 25px;
    }
}


