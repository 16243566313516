header {
  position: relative;
  margin: 100px 0 25px 0;
  font-size: 2.1em;
  text-align: center;
  letter-spacing: 7px;
  color: #bf8d7a;
}

.contact-form-container {
  position: relative;
  width: 500px;
  margin: 50px auto 100px auto;
}

#contact-form-wrapper input {
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-size: 0.975em;
  width: 470px;
  height: 50px;
  padding: 0px 15px 0px 15px;
  background: transparent;
  outline: none;
  color: #6b4b33;
  border: solid 1px #b3aca7;
}

input, textarea {
  font-family: 'Barlow Semi Condensed', sans-serif;
  width: 100%;
  padding: 15px;
  background: transparent;
  outline: none; 
  color: #6b4b33;
  font-size: 0.975em;
  border: solid 1px #b3aca7;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}
input:hover, textarea:hover {
  background: #ddcbb8;
  color: #6b4b33;
}
.success-msg {
  color: #128157;
  padding-top: 20px;
}
.error-msg {
  color: #EE6352;
  padding-top: 20px;
}
@media only screen and (max-width: 760px) {
  .contact-form-container {
    left: 0;
    right: 0;
    width: 50%;
  }
  header {
    margin: 80px 0 15px 0;
    font-size: 1.2em;
  }
}