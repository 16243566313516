.about-me {
    font-size: large;
    line-height: 1.6;
}
.about-me-img {
    border-radius: 8px;
    height: 270px;
    margin-left: 15px;
    float: right;
}
.about-me-paragraph {
    font-family: 'Cormorant Garamond', serif;
    line-height: 40px;
    font-size: 2.7vh;
}
@media screen and (max-width: 760px) {
    .about-me-header {
        margin-top: 80px;
        margin-bottom: 0;
    }
    .about-me {
        font-size: medium;
    }
    .about-me-img {
        height: 150px;
    }
    .about-me-paragraph {
        line-height: 20px;
        font-size: 2.4vh;
    }
}