html {
  scroll-behavior: smooth;
}
.navbar {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
  top: 10px;
}
.nav {
  display: flex;
  justify-content: right;
  list-style: none;
  margin-right: 20px;
  font-weight: bold;
}
.nav a {
  font-family: 'Cormorant Garamond', serif;
  color: #756254;
  text-decoration: none;
  margin: 15px;
  cursor: pointer;
}
.nav a:hover {
  color: #bf8d7a;

}
.logo {
  z-index: 1000;
  position: fixed;
  width: 80px;
  left: 15px;
  top: 0;
}
@media screen and (max-width: 760px) {
  .nav {
    margin-right: 0;
  }
}