.skills-icons {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
.icon {
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: center;
    width: 80px;
    height: 80px;
}
@media only screen and (max-width: 760px) {
    .skills-header {
        margin-top: 80px;
    }
    .icon {
        grid-template-columns: repeat(2, 1fr);
        width: 50px;
        height: 50px;
    }
}

.skills-container {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* grid-template-columns: repeat(4, 150px); */
    /* grid-template-rows: repeat(5, 150px) */
}

h4 {
    display: flex;
    justify-content: center;
}