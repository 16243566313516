.bio-intro {
    font-size: 6.5vh;
    line-height: 1.5em;
    font-family: 'Cormorant Garamond', serif;
}
.ts-name {
    font-family: 'Cormorant Garamond', serif;
    color: #bf8d7a;
    font-size: 8vh;
    font-weight: bold;
}
@media only screen and (max-width: 760px) {
    .bio-intro {
      font-size: 4vh;
    }
    .ts-name {
        font-size: 6vh;
    }
}